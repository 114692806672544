<template>
    <!-- <div class="documents-list" v-if="translates && translates[langUrl]">
        <div class="documents-list__title">{{ translates[langUrl].listPage_title[lang] }}</div>
        <div class="documents-list__container">
            <template v-for="(item, i) in documents">
                <div 
                    :key="i"
                    class="documents-list__item"
                    @click="open( item.code )">
                    <div class="documents-list__item__picture" :style="`background-image: url(${ imageSrc(item.picture) })` "></div>
                    <div class="documents-list__item__text">
                        <div class="documents-list__item__date">{{ postTime(item.createdAt) }}</div>
                        <div class="documents-list__item__name">{{ item.title }}</div>
                        <div class="documents-list__item__description">{{ item.preview }}</div>
                        <div class="documents-list__item__more">{{ translates[langUrl].documents_detail[lang] }}</div>
                    </div>
                </div>
            </template>
        </div>
    </div> -->
    <div class="documents-list" v-if="translates && translates[langUrl]">
        <div class="documents-list__title">{{ translates[langUrl].pageTitle[lang] }}</div>
        <div class="documents-list__container">
            <template v-for="(item, index) in documents">
                <div 
                    :key="`document-${index}`"
                    class="documents-list__item">
                    <div class="documents-list__item__picture">
                        <div v-if="item.trip && item.trip.pictures && item.trip.pictures.length !== 0" 
                            :style="`background-image: url(${ imageSrc(item.trip.pictures[item.trip.mainPictureIndex]) })`" 
                            class="documents-list__item__picture__img"
                        ></div>
                    </div>
                    <div class="documents-list__item__info">
                        <div class="documents-list__item__trip-title" v-if="item.trip && item.trip.name">
                            {{item.trip.name}}
                        </div>
                        <div class="documents-list__item__files">
                            <template v-for="(document, i) in item.documents">
                                <div 
                                    v-if="document.file && document.title"
                                    :key="`document-${index}-file-${i}`"
                                    class="documents-list__item__files__item">
                                    <a :href="imageSrc(document.file)" target="_blank">
                                        <v-icon>mdi-file</v-icon> {{document.title}}
                                    </a>
                                </div>
                            </template>
                        </div>
                        <div class="documents-list__item__comment" v-if="item.comment">
                            {{item.comment}}
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { imageSrc } from '@/helpers';
    import moment from '@/plugins/moment';


    export default {
        name: 'DocumentsList',
        metaInfo() {
            return {
                title: this.translates[this.langUrl] ? this.translates[this.langUrl].browserTitle[this.lang] : ''
            }
        },
        components: {},
        data: () => ({
            imageSrc,
            langUrl: '/user/documents'
        }),
        computed: {
            ...mapState('documents', {
                documents: state => state.entities
            }),
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        async mounted() {
            this.$root.$emit('preloaderShow');
            await this.getDocuments();
            this.$root.$emit('preloaderHide');
        },
        methods: {
            async getDocuments() {
                await store.dispatch('documents/fetch', {});
            },
            postTime(date) {
                return moment(date).calendar()
            },
            async open(code) {
                // await store.commit('documents/CLEAR_ENTITY');
                await this.$router.push({ name: 'documents', params: { code } });
            },
            checkPosition() {
                // Нам потребуется знать высоту документа и высоту экрана:
                const height = document.body.offsetHeight
                const screenHeight = window.innerHeight

                // Они могут отличаться: если на странице много контента,
                // высота документа будет больше высоты экрана (отсюда и скролл).

                // Записываем, сколько пикселей пользователь уже проскроллил:
                const scrolled = window.scrollY

                // Обозначим порог, по приближении к которому
                // будем вызывать какое-то действие.
                // В нашем случае — четверть экрана до конца страницы:
                const threshold = height - screenHeight / 4

                // Отслеживаем, где находится низ экрана относительно страницы:
                const position = scrolled + screenHeight

                if (position >= threshold) {
                    // Если мы пересекли полосу-порог, вызываем нужное действие.
                }
            }
        }
    }

</script>

<style lang="scss">
    .documents-list {
        &__title {
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: #3A4256;
            margin: 32px 24px 12px;
            @media all and (min-width:768px) {
            font-weight: bold;
            font-size: 38px;
            line-height: 46px;
            color: #273155;
            margin: 40px 0 36px;
            }
        }

        &__item {
            display: flex;
            width: 100%;
            min-height: 320px;
            background: #FFFFFF;
            border-radius: 20px;
            margin-bottom: 20px;
            overflow: hidden;
            box-shadow: 0px 4px 3px rgb(44 47 56 / 2%), 0px 4px 16px rgb(44 47 56 / 12%);

            &__picture {
                width: 360px;
                min-height: 100%;

                &__img {
                    width: 360px;
                    min-height: 100%;
                    background-size: cover;
                }
            }

            &__info {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 30px 20px;
            }

            &__trip-title {
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                letter-spacing: -0.02em;
                margin-bottom: 10px;
            }

            &__files {
                .v-icon {
                    color: #da0a63;
                    margin-right: 5px;
                }
                a {
                    text-decoration: none;
                }
            }
        }
    }
</style>